
const initState = {
   projectCreated : true
  }


const projectReducer  = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE__MAIN_PROJECT':
            console.log('Project Created', action.project);
            return {
                ...state,
                projectCreated : true
            }
            case 'CREATE_MAIN_PROJECT_ERROR':
            console.log('Create comment error', action.err);
            return state;
        default:
            return state;
    }
    
}

export default projectReducer

