import React, { Component } from 'react'
import {createUser} from '../../../Store/Actions/userOrgActions'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/firestore';
import { firestore } from 'firebase'; 

class ProjectAddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: [],
            docID: this.props.orgID
        }
    }
    


    handleChange = (e) => {
        
            this.setState({
                [e.target.id]: e.target.value
            })
       
        }

    handleSubmit = (e) => {
        
        e.preventDefault();
        //console.log(this.props.profile); 

        var db = firebase.firestore();
    

                const thisEmail = this.state.email;

               

                db.collection("invited").doc(thisEmail).set({
                    email : thisEmail,
                    inviteAccepted : false,
                    from: this.props.profile.organisation
                })
                .then(function() {
                    console.log("Document successfully written!");
                })
                .catch(function(error) {
                    console.error("Error writing document: ", error);
                });

                db.collection("organisations").where('organisation', '==', this.props.profile.organisation).get()
                .then(function(querySnapshot) { 
                 querySnapshot.forEach(doc => {
                 db.collection("organisations").doc(doc.id).update({
                        pendingInvite: firestore.FieldValue.arrayUnion(thisEmail)
                      })    
                   })  
                 })   
                document.getElementById("email").value = "";
                
        }

            
    

    render() {
 
        return(
                
                <div>
                    <h3 className="add-title">Add User:</h3>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                        
                            <input type="email" id="email" onChange={this.handleChange} className='form-control add-email-input' placeholder='New Email'></input>
                        </div>
                        <div className="form-group">
                            <input type="submit" className="btn btn-primary"  />
                        </div>
                    </form>
                    
                </div>    
            
        )
    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        createUser: (user) => dispatch(createUser(user))
    }
}

export default connect(null, mapDispatchToProps)(ProjectAddUser)