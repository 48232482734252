import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
// import Notifications from './Notifications'
// import SearchMain from './Search/SearchMain'
import { ReactComponent as Logo } from '../../assets/tribe-logo-02-20.svg';
import { ReactComponent as HeaderBg } from '../../assets/header-svg.svg';

import firebase from 'firebase/app'
import 'firebase/firestore';
import { firestore } from 'firebase'; 



class NavComponent extends Component {
    constructor(props) {
		super(props)
		this.state = {
            invitedData: null,
            email: this.props.profile.email
        }
        

    }
    
    inviteAccepted = () => {
        var self = this;
        const userEmail = self.props.profile.email;
        if(userEmail){
            var db = firebase.firestore();
        var docRef = db.collection("invited").doc(userEmail);
        
        docRef.get().then(function(doc) {
            if (doc.exists) {
                docRef.update({
                    inviteAccepted : true
                 }) 
                 document.getElementById("invitePopup").style.display = "none";
            }
        })
        }
            const thisOrg = self.state.invitedData.from


            db.collection("users").where('email', '==', userEmail).where('organisation', '==', '').get()
               .then(function(querySnapshot) { 
                querySnapshot.forEach(doc => {
                db.collection("users").doc(doc.id).update({
                    organisation : thisOrg
                     })    
                  })  
                })
                
                //accepted invite
                db.collection("organisations").where('organisation', '==', this.state.invitedData.from).get()
                .then(function(querySnapshot) { 
                 querySnapshot.forEach(doc => {
                 db.collection("organisations").doc(doc.id).update({
                        pendingInvite: firestore.FieldValue.arrayRemove(userEmail),
                        clients : firestore.FieldValue.arrayUnion(userEmail)
                      })    
                   })  
                 })

                //window.location.reload(); 
            
    }

    inviteDeclined = () => {
        var self = this;
        if(self.props.profile.email){
            var db = firebase.firestore();
        var docRef = db.collection("invited").doc(self.props.profile.email);
        
        docRef.get().then(function(doc) {
            if (doc.exists) {
                docRef.update({
                    declined : true
                 }) 
                 document.getElementById("invitePopup").style.display = "none";
            }
        })
        }
    }

    componentDidMount() {
        
        var self = this;
        const userEmail = this.props.profile.email
        //console.log('email? = ' + userEmail)
        if(userEmail){
            var db = firebase.firestore();
        var docRef = db.collection("invited").doc(userEmail);
        docRef.get().then(function(doc) {
            var data = doc.data();
            if (data.email === userEmail && data.inviteAccepted === false && data.declined !== true) {
                console.log("Document data:", doc.data());
                self.setState({
                    invitedData: doc.data()
                  })
                 
                document.getElementById("invitePopup").style.display = "block";
            } else {
                // doc.data() will be undefined in this case
                document.getElementById("invitePopup").style.display = "none";
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
        }
    }

    
    render() {
        var navStyle = {
            backgroundImage: `url(${HeaderBg})`
          };
        const links = this.props.auth.uid && this.props.profile ? <SignedInLinks profile={this.props.profile} /> : <SignedOutLinks />;

        // check if user logged in is invited
       
       
        
        
        
return (
    <>
    <nav style={navStyle} className="header-nav">
  
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <Link to='/' className='navbar-brand'>
                    <Logo />
                    </Link>
                    {/* {notifications} */}
                    <ul className="nav-links">
                        { links }
                    </ul>
                    <div className="mob-nav-links">
                        <p>Mob nav to be made</p>
                        {/* { links } */}
                    </div>
                </div>
            
            </div>
            
             
             {/* search when logged in */}
            
            </div>
        </nav>
        {this.state.invitedData ?
             <div className="invite-window" id="invitePopup">
             <div className="row">
                 <div className="col-md-12 text-center">
                     <h1>You've been invited to {this.state.invitedData.from}</h1>
                 </div>
             </div>
             <div className="row">
                 <div className="col-md-6 text-center">
                     <button onClick={this.inviteAccepted} className="invite-accept btn-success">Accept</button>
                 </div>
                 <div className="col-md-6 text-center">
                     <button onClick={this.inviteDeclined} className="invite-decline btn-danger">Decline</button>
                 </div>
             </div>
            
 
         </div>
        : null}
       
        {/* {subMenu} */}
        </>
         )    
        }
        
    }




    
    export default NavComponent