import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'

import { Provider, useSelector } from 'react-redux'
import thunk from 'redux-thunk'
import { reduxFirestore, getFirestore, createFirestoreInstance, firestoreReducer } from 'redux-firestore'
import { getFirebase, ReactReduxFirebaseProvider, firebaseReducer, isLoaded } from 'react-redux-firebase'
import fbConfig from './config/fbConfig'
import firebase from 'firebase/app'
import authReducer from './Store/Reducers/authReducer'
import projectReducer from './Store/Reducers/projectReducer'
import authReducerOrg from './Store/Reducers/authReducerOrg'
import commentReducer from './Store/Reducers/commentReducer'
import addUserOrg from './Store/Reducers/addUserOrg'


if (!firebase.apps.length) {
    firebase.initializeApp(fbConfig);
}

firebase.firestore();
firebase.storage().ref();
firebase.functions();

const rrfConfig = {
    useFirestoreForProfile: true,
    userProfile: 'users',
    attachAuthIsReady: true,
    allowMultipleListeners: true
}



const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    auth: authReducer,
    authOrg: authReducerOrg,
    project: projectReducer,
    comment: commentReducer,
    addUserOrg: addUserOrg
  })

  const initialState = {}


const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})),
      reduxFirestore(fbConfig),
    )
  );    
  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
}

function AuthIsLoaded({ children }) {
    const auth = useSelector(state => state.firebase.auth)
    if (!isLoaded(auth)) return <div>splash screen...</div>;
    return children
  }


    

// Setup react-redux so that connect HOC can be used
ReactDOM.render(
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <AuthIsLoaded>
                <App />
            </AuthIsLoaded>
         
        </ReactReduxFirebaseProvider>
      </Provider>,
    document.getElementById('root'));
  





// const store = createStore(rootReducer,
// compose(    
//     reduxFirestore(fbConfig),
//     reactReduxFirebase(fbConfig, {useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true, allowMultipleListeners: true})
//     )
// );

// store.firebaseAuthIsReady.then(() => {

//     ReactDOM.render(<Provider store={store}>
//         <App />
//     </Provider>, 
// document.getElementById('root'));
// })




// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
