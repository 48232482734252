import React, { Component } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore';



class UninviteUser extends Component {
    state = {
        userEmail: this.props.userEmail,
        orgID: this.props.orgID
    }

    
   

    handleDeleteUser = (e) => {
        e.preventDefault();  
        
        function confirmDialog(msg) {
            return new Promise(function (resolve, reject) {
              let confirmed = window.confirm(msg);
          
              return confirmed ? resolve(true) : reject(false);
            });
           }

        confirmDialog('Do you really want to delete this?')
        .then(() => {

            var db = firebase.firestore();

            const userEmail = this.props.userEmail
            db.collection('organisations').doc(this.props.orgID).update({

                pendingInvite : firebase.firestore.FieldValue.arrayRemove(userEmail) 
            })

            db.collection("invited").doc(userEmail).delete().then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
        })
        }


    render() {

        

        
 
        return(
                
               
                  
                  <button id={'button' + this.props.userEmail} onClick={this.handleDeleteUser} className="btn btn-primary delete-btn">Delete</button>
                    
                   
            
        )
    }
}





export default UninviteUser