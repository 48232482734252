import React  from 'react'
import ProjectComment from './ProjectComment'



const ProjectComments = ({comments,currentProject, theProjectId, profile, view}) => {

        //mark comments as read
        //If user scrolls past comment, add UID to hasRead array in comment document
        //when displaying comment in status, do not include ones with UID in hasRead
    function onLoad() {
        var hash = window.location.hash;
        console.log(hash);
        if (hash !== '') {
            // Push onto callback queue so it runs after the DOM is updated,
            // this is required when navigating from a different page so that
            // the element is rendered on the page before trying to getElementById.
            setTimeout(() => {
              const id = hash.replace('#', '');
              const element = document.getElementById(id);
              if (element && element.scrollIntoView) {
                element.scrollIntoView();
              }
            }, 0);
          }
       
    }
 

    return(
        <div className="comment-list section row">

            <div onLoad={onLoad()} className="col-md-12">
            <h1>Comments</h1>
            </div>
            
        
            { comments && comments.map(comment => {
              
           
               
                    return(
                        <>
                        {comment.id ?
                        
                            <div className="col-md-12" id="canView">
                            <div id={comment.id}>
                                <ProjectComment profile={profile} commentDocumentId={comment.id} currentProject={currentProject} theProjectId={theProjectId} comment={comment} key={comment.id}/>
                            </div>
                            
                            </div>
                              : null}
                        </>
                            
                    )
                 
               
               
            })}

           
        </div>
    )
}

export default ProjectComments
