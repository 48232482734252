import React, { Component} from 'react'
import {Link} from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/firestore';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
var options = []

class EditInnerProject extends Component {
    state = {
        title: '',
        content: '',
        hideModal: false,
        showModal: '',
        theProject: '',
        clients: [],
        loadedOnce : false,
        customerCheck: false,
        currentClients: []
    }   

    handleSelectChange = clients => {
        // console.log(clients)
        // console.log(clients[0].value)
        var i = 0;
        var clientsArray = [];
        if(clients){
            while (i < clients.length){
                console.log(clients[i].value)
                clientsArray.push(clients[i].value);
                console.log('clientsArray = ' + clientsArray)
                this.setState({ 
                    clients : clientsArray
                },
                () => console.log(`Option selected:`, this.state.clients)
                );
                 i++;
            }
        }else {
            this.setState({ 
                clients : clientsArray
            },
            () => console.log(`No clients, proof = `, this.state.clients)
            );
        }
        
      };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
       
    }

    handleClose = () => {
        this.setState({
            show : false
        })
    }
    handleShow = (e) => {
        const projectToEdit = e.target.parentElement.getAttribute('id');
        this.setState({
            show : true,
            theProject : projectToEdit
        })

        var db = firebase.firestore();
        const ref = this;
         var docRef = db.collection('innerprojects').doc(projectToEdit)
         docRef.get().then(function(doc) {
             var data = doc.data();
             if (doc.exists) {
                ref.setState({
                    title: data.title,
                    content: data.content
                })
                document.getElementById("title").value = data.title;
                document.getElementById("content").value = data.content;
             }else {
                 console.log("No Project")
             }
         })

         var currentClientsNow = []
         if(this.props.clients) {
            this.props.clients.forEach(client => {
               
                 currentClientsNow.push({
                     value: client, label : client
                 })
                 
                
            })
           // console.log(currentClientsNow)
            ref.setState({
             currentClients : currentClientsNow
         })
         }
       
        
    }

    handleSubmit = (e) => {

        e.preventDefault();
        var db = firebase.firestore();
        const projectToEdit = this.state.theProject
    
  
        
             db.collection("innerprojects").doc(projectToEdit).update({
                title : this.state.title,
                content: this.state.content,
                clients: this.state.clients,
            })
        

    }

    render() {

        if (this.props.profile.organisation && this.state.loadedOnce === false) {

            var db = firebase.firestore();
                if(this.props.profile.organisation) {
                    
                    db.collection("organisations")
                    .where('canView', 'array-contains', this.props.profile.email)
                    .get()
                    .then(querySnapshot => {
                        options = [];
                        querySnapshot.forEach(doc => {
                            var data = doc.data();
                            var i = 0;
                           //load clients for drop down
                            while(i < data.clients.length ){
                                //console.log(data.clients[i])
                                var newItem = ({
                                    value: data.clients[i], label: data.clients[i]
                                })
                                
                                options.indexOf(newItem) === -1 ? options.push(newItem) : console.log("This item already exists");

                                i++
                            }
                           
                            
                        })
                     
                  });
         
                }    
                this.setState({
                    loadedOnce : true
                })    
                //console.log('clients? = ' + this.state.clientsInProject)
         }

        return(
            <>
        <Link variant="primary" onClick={this.handleShow} className="dropdown-item">Edit</Link>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form onSubmit={this.handleSubmit}>
        
                <div className="form-group">
                <Select
                        value={this.state.selectedOption}
                        onChange={this.handleSelectChange}
                        defaultValue={this.state.currentClients}
                        isMulti
                        options={options}
                        placeholder= 'Add Customers'

                    />
                    </div>
            
            <div className="form-group">
                <input onChange={this.handleChange} className='form-control' type="text" id='title' placeholder='Project Title'/>
            </div>
                <div className="form-group">
                                    
                    <textarea
                        onChange={this.handleChange}
                        className='form-control'
                        id="content"
                        rows="3"
                        placeholder='Project Content (can add some more options here later)'>

                    </textarea>
                </div>
                 <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                    Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={this.handleClose}>
                    Save Changes
                    </Button>
          </Modal.Footer>
            </form>

          </Modal.Body>
          
        </Modal>
      </>
        )
    }
}



export default (EditInnerProject)