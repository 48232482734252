import React, { Component} from 'react'
import ProjectListDetails from './ProjectListDetails'

class ProjectList extends Component {
    state = {
  
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return this.props.projects !== nextProps.projects;
    //   }


      
  render() {

    // console.log('projects = ' + this.props.projects)
   
    return(
        

        <div className="project-list section">
           
            {
                
                this.props.projects && this.props.projects.map(project => {
                
                const projectId = project.authorID + project.createdAt.toDate().toLocaleTimeString('it-it') + project.title
                

                if(this.props.profile){
                    
                    
                return(

               <>
                    
                  
                    {project.canView.includes(this.props.profile.email)  || project.authorEmail.includes(this.props.profile.email) || (this.props.profile && project.organisation === '' && project.authorEmail.includes(this.props.profile.email))  ?
                        
                    <ProjectListDetails auth={this.props.auth} project={project} profile={this.props.profile} projectId={projectId}></ProjectListDetails>
                            
                    : null}

                    {project.clients && project.clients.includes(this.props.profile.email) ? 

                        <ProjectListDetails auth={this.props.auth} project={project} profile={this.props.profile} projectId={projectId}></ProjectListDetails>

                    : null }
                  
                </>
                  
                    
                )
            }
            return null

            })}
        </div>
     
    )
    }
}


export default ProjectList
