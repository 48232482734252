import React, { Component} from 'react'
import { connect } from 'react-redux'
import { createInnerProject } from '../../../Store/Actions/projectActions'
import firebase from 'firebase/app'
import 'firebase/firestore';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import addButtonBg from '../../../assets/add-button-bg.png';
import Select from 'react-select';
var options = []

class CreateInnerProject extends Component {
    state = {
        title: '',
        content: '',
        projectId: this.props.projectId,
        projectAmount: '',
        show : false,
        setShow : false,
        organisation: this.props.project.organisation,
        admin: this.props.project.admin,
        canView: this.props.project.canView,
        projectTitle: this.props.project.title,
        clients: [],
        loadedOnce : false,
        customerCheck: false
    }

    handleSelectChange = clients => {
        var i = 0;
        var clientsArray = [];
        if(clients){
            while (i < clients.length){
                console.log(clients[i].value)
                clientsArray.push(clients[i].value);
                console.log('clientsArray = ' + clientsArray)
                this.setState({ 
                    clients : clientsArray
                },
                () => console.log(`Option selected:`, this.state.clients)
                );
                 i++;
            }
        }
      };

    

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
       
    }

    handleSubmit = (e) => {
      e.preventDefault();

      var projectClients = this.props.project.clients
      if(this.props.project.clients) {
          this.setState({
              clients : projectClients
          })
      }
      
      this.props.createInnerProject(this.state) 

      //for status bar

           document.getElementById("title").value = "";
           document.getElementById("content").value = "";

    }

    handleClose = () => {
        this.setState({
            show : false
        })
    }
    handleShow = () => {
        this.setState({
            show : true
        })
    }
    

    render() {

        var buttonBgStyle = {
            backgroundImage: `url(${addButtonBg})`
          };

          if (this.props.profile.organisation && this.state.loadedOnce === false) {

            var db = firebase.firestore();
                if(this.props.profile.organisation) {
                    
                    db.collection("organisations")
                    .where('canView', 'array-contains', this.props.profile.email)
                    .get()
                    .then(querySnapshot => {
                        options = [];
                        querySnapshot.forEach(doc => {
                            var data = doc.data();
                            var i = 0;
                           //load clients for drop down
                            while(i < data.clients.length ){
                                //console.log(data.clients[i])
                                var newItem = ({
                                    value: data.clients[i], label: data.clients[i]
                                })
                                
                                options.indexOf(newItem) === -1 ? options.push(newItem) : console.log("This item already exists");

                                i++
                            }
                           
                            
                        })
                     
                  });

                  db.collection("organisations")
                    .where('organisation', '==', this.props.profile.organisation)
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            var data = doc.data();
                            if(data.clients.includes(this.props.profile.email)){
                                this.setState({
                                    customerCheck: true
                                })
                            }
                        })
                    })
                }    
                this.setState({
                    loadedOnce : true
                })    
         }
         

        return(
            <div className="row">
                <div className="col-md-12">
                <div className="create-new-banner" style={buttonBgStyle} variant="primary" onClick={this.handleShow}>
                    <h1> <FontAwesomeIcon icon={faPlus} /> Add New Gathering</h1>
                </div>
                    <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Gathering</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form onSubmit={this.handleSubmit}>
                   
                        <div className="form-group">
                            <Select
                                    value={this.state.selectedOption}
                                    onChange={this.handleSelectChange}
                                    defaultValue='Add Customers'
                                    isMulti
                                    options={options}
                                    placeholder= 'Add Customers'

                                />
                                </div>
                          
                        <div className="form-group">
                            <input onChange={this.handleChange} className='form-control' type="text" id='title' placeholder='Gathering Title'/>
                        </div>
                            <div className="form-group">
                                                
                                <textarea
                                    onChange={this.handleChange}
                                    className='form-control'
                                    id="content"
                                    rows="3"
                                    placeholder='Gathering Description'>

                                </textarea>
                            </div>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleClose}>
                                Close
                                </Button>
                                <Button type="submit" variant="primary" onClick={this.handleClose}>
                                Save Changes
                                </Button>
                    </Modal.Footer>
                        </form>

                    </Modal.Body>
                    
                    </Modal>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        organisations: state.firestore.ordered.organisations
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        createInnerProject: (project) => dispatch(createInnerProject(project))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInnerProject)