import React, { Component} from 'react'
import { connect } from 'react-redux'
import { createProject } from '../../Store/Actions/projectActions'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import firebase from 'firebase/app'
import 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
var options = []





// import ReactQuill from 'react-quill'
// import 'react-quill/dist/quill.snow.css'
// import renderHTML from 'react-render-html';

class CreateProject extends Component {
    state = {
        title: '',
        content: '',
        hideModal: false,
        showModal: '',
        show : false,
        setShow : false,
        canView:[this.props.auth.email],
        organisation:'',
        admin:[],
        clients: [],
        loadedOnce : false,
        customerCheck: false
    }


    handleSelectChange = clients => {
        var i = 0;
        var clientsArray = [];
        if(clients){
            while (i < clients.length){
                console.log(clients[i].value)
                clientsArray.push(clients[i].value);
                console.log('clientsArray = ' + clientsArray)
                this.setState({ 
                    clients : clientsArray
                },
                () => console.log(`Option selected:`, this.state.clients)
                );
                 i++;
            }
        }
      };

    

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
       
    }

    handleSubmit = (e) => {
     
      e.preventDefault();
         
       this.setState({ canView: [] }); 

       this.props.organisations && this.props.organisations.map(organisation => {
        
        if (organisation.canView.includes(this.props.profile.email)) {
            this.state.admin.push(organisation.ownerEmail);
            const orgUser = organisation.canView.map((item, key) =>
                this.state.canView.push(item)
            );
            return orgUser
        }

        if(this.state.clients.length > 0){
            this.state.canView.push(this.state.clients)
        }
        
       
        
          return null
    
       })      
       this.props.createProject(this.state) 

       document.getElementById("title").value = "";
       document.getElementById("content").value = "";

      //add admins to project
      this.setState({
        clients : []
    })

       

    }

    handleClose = () => {
        this.setState({
            show : false
        })

        
       
    }
    handleShow = () => {
        this.setState({
            show : true
        })
    }

 


    render() {
       
        if (this.props.profile.organisation && this.state.loadedOnce === false) {

            var db = firebase.firestore();
                if(this.props.profile.organisation) {
                    
                    db.collection("organisations")
                    .where('canView', 'array-contains', this.props.profile.email)
                    .get()
                    .then(querySnapshot => {
                        options = [];
                        querySnapshot.forEach(doc => {
                            var data = doc.data();
                            var i = 0;
                           //load clients for drop down
                            while(i < data.clients.length ){
                                //console.log(data.clients[i])
                                var newItem = ({
                                    value: data.clients[i], label: data.clients[i]
                                })
                                
                                options.indexOf(newItem) === -1 ? options.push(newItem) : console.log("This item already exists");

                                i++
                            }
                           
                            
                        })
                     
                  });

                  db.collection("organisations")
                    .where('organisation', '==', this.props.profile.organisation)
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            var data = doc.data();
                            if(data.clients.includes(this.props.profile.email)){
                                this.setState({
                                    customerCheck: true
                                })
                            }
                        })
                    })
                }    
                this.setState({
                    loadedOnce : true
                })    
         }

         
           
       
        return(
          <>
            <Button  className="btn btn-primary plus-btn" variant="primary" onClick={this.handleShow}>
            <FontAwesomeIcon icon={faPlus} />
            </Button>
      
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>New Project</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <form onSubmit={this.handleSubmit}>
                  {this.state.customerCheck === false ? 
                        <div className="form-group">
                        <Select
                                value={this.state.selectedOption}
                                onChange={this.handleSelectChange}
                                defaultValue='Add Customers'
                                isMulti
                                options={options}
                                placeholder= 'Add Customers'

                            />
                         </div>
                  : null}
                  
                <div className="form-group">
                    <input onChange={this.handleChange} className='form-control' type="text" id='title' placeholder='Project Title'/>
                </div>
                    <div className="form-group">
                                        
                        <textarea
                            onChange={this.handleChange}
                            className='form-control'
                            id="content"
                            rows="3"
                            placeholder='Project Content (can add some more options here later)'>

                        </textarea>
                    </div>
                     <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                        Close
                        </Button>
                        <Button type="submit" variant="primary" onClick={this.handleClose}>
                        Save Changes
                        </Button>
              </Modal.Footer>
                </form>

              </Modal.Body>
              
            </Modal>
         </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        organisations: state.firestore.ordered.organisations
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        createProject: (project) => dispatch(createProject(project))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject)